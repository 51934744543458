import React, { useState,useRef } from "react";
//import Layout from '../../components/layout'
import { Container, Row, Col, Form, FormGroup, Input,CustomInput,Label,Progress } from 'reactstrap'
import axios from "axios";
import LoaderImg from '../../images/loader/loader.gif'
import { v1 as uuidv1 } from 'uuid';


const Loader = (props) => (
    <div className="loader_bg">
        <img src={LoaderImg} alt="img" />
    </div>
)
const ReferralForm = (props) => {

    const inputRef = useRef();
    const [showLoader, setShowLoader] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [shouldShowError, setShouldShowError] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [progress, setProgress] = useState(0);

    const [referralName, setReferralName] = useState('');
    const [referralEmail, setReferralEmail] = useState('');
    const [referralPhone, setReferralPhone] = useState('');
    const [referralUrl, setReferralUrl] = useState('');
    const [referralSkill, setReferralSkill] = useState('iOS');

    // const [isUserNameValid, setIsUserNameValid] = useState(false)
    const [isUserNameEmpty, setIsUserNameEmpty] = useState(false)
    const [isUserEmailValid, setIsUserEmailValid] = useState(false)
    const [isUserEmailEmpty, setIsUserEmailEmpty] = useState(false)

    // const [isReferralNameValid, setIsReferralNameValid] = useState(false)
    const [isReferralNameEmpty, setIsReferralNameEmpty] = useState(false)
    const [isReferralEmailValid, setIsReferralEmailValid] = useState(false)
    const [isReferralEmailEmpty, setIsReferralEmailEmpty] = useState(false)
    
    
    // const [isValid, setisValid] = useState(false)
    //const [isEnterPressed, setisEnterPressed] = useState(false)
    // const [handleSubmitClicked, sethandleSubmitClcicked] = useState(false)
    // const [isLastNameEmpty, setLastName] = useState(false)

    function encode(data) {
        const formData = new FormData();

        for (const key of Object.keys(data)) {
            formData.append(key, data[key]);
        }

        return formData;
    }

    const updateProgress = (increment,prog,fun) =>{

            setTimeout(() => {
                fun(prog)
            }, increment);
    }
    const handleAttachment = async (event,result) => {

        let uuid = uuidv1()
        let newFileName = uuid + "-" + inputRef.current.files[0].name 
        const formdata = new FormData();
        formdata.append(inputRef.current.name, inputRef.current.files[0], newFileName);
        setProgress(10)
        
        fetch("/api/upload-resume", {
            method: 'POST',
            headers: {},
            body: formdata
        })
        .then((response) => {
            setProgress(100)
            return response.json();
        })
        .then((response) => {
            setReferralUrl(response.uploadURL)
            console.log(response);
        })
        .catch((error) => {
            setProgress(0)
            console.error(error);
        });

        // var config = {
        //     headers: {},
        //     onUploadProgress: function(progressEvent) {
        //       setProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
        //     }
        // };
        // axios.put('/upload/server', formdata, config)
        // .then((response) => {
        //     console.log(response);
        //     setProgress(100)
        // })
        // .catch((error) => {
        //     console.error(error);
        // });
    

        // axios
        // .post("/api/upload-resume", formdata, {
        //     headers: {
        //     'Content-Type': 'multipart/form-data'
        //     }
        // })
        // .then(res => {
        //     setProgress(100)
        //     console.log(res)
        // })
        // .catch(err => {
        //     console.log(err);
        // });

        // for(var i=1; i<=9; i++){
        //     updateProgress(i*1000,i*10,setProgress)
        // }
    }
    const handleSubmit = async event => {
        event.preventDefault();
        setShouldShowError(true)
        setShowLoader(true)

        if (userName === "") {
            setIsUserNameEmpty(true)
        } else {
            setIsUserNameEmpty(false)
        }
        if (userEmail === "") {
            setIsUserEmailEmpty(true)
        } else {
            setIsUserEmailEmpty(false)
        }

        if (referralName === "") {
            setIsReferralNameEmpty(true)
        } else {
            setIsReferralNameEmpty(false)
        }
        if (referralEmail === "") {
            setIsReferralEmailEmpty(true)
        } else {
            setIsReferralEmailEmpty(false)
        }

        if (userEmail === '' || !isUserEmailValid || userName === '' || referralEmail === '' || !isReferralEmailValid || referralName === '') {
            setShowLoader(false)
            return;
        }
        try{
            let data = await axios.post('/api/refer-a-friend', { userName, userEmail, userPhone, referralName, referralEmail, referralPhone,referralUrl,referralSkill });
            console.log(data)
            setReferralName('');
            setReferralEmail('');
            setReferralPhone('');
            setShowLoader(false)
            alert("Thank you for your referral, one of us will get in touch with you shortly.")
            props.close();
        }catch(err){
            setShowLoader(false)
            console.log(err)
        }
        
        
        
        //navigate(`/Company/Blog/ThankYou?redirect=${props.pathname}&redirectTitle=${props.redirectTitle}`);
    }

    const userEmailValidation = (value) => {
        setUserEmail(value)
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(value).toLowerCase())) {
            setIsUserEmailValid(true)
            console.log(`valid email:${value}`)
        } else {
            setIsUserEmailValid(false)
            console.log(` not a valid email:${value}`)
        }
    }

     const referralEmailValidation = (value) => {
        setReferralEmail(value)
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(value).toLowerCase())) {
            setIsReferralEmailValid(true)
            console.log(`valid email:${value}`)
        } else {
            setIsReferralEmailValid(false)
            console.log(` not a valid email:${value}`)
        }
    }

const userNameValidation = (value) => {
        setUserName(value)
        if (value === "") {
            console.log('Fild  empty')
            setIsUserNameEmpty(true)
        } else {
            setUserName(value)
            setIsUserNameEmpty(false)
        }
}
const referralNameValidation = (value) => {
        setReferralName(value)
        if (value === "") {
            console.log('Fild  empty')
            setIsReferralNameEmpty(true)
        } else {
            setUserName(value)
            setIsReferralNameEmpty(false)
        }
}

const onChangeSkill = (value) => {
     //alert(value)
     setReferralSkill(value)

}

return (
    <section>
        {/* <div class="loader"></div> */}
        {showLoader ? <Loader/>:""}
        <Container>
            <Form>
                <div className="form_text_h">
                    <h2 className="form_heading">{props.title}</h2>
                </div>
               
                <Row  className="refer_box">
                     <Label className="title_label" for="userInfo">Your Info</Label>
                    <Col md={6}>
                        <FormGroup>
                            <Input bsSize="md" type="text" name="name" placeholder="Name" valid={!isUserNameEmpty && shouldShowError ? true : false} invalid={isUserNameEmpty && shouldShowError ? true : false} value={userName} onChange={event => userNameValidation(event.target.value)} onFocus={()=>setShouldShowError(false)}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Input bsSize="md" type="text" name="number" placeholder="Phone (Optional)" />
                        </FormGroup>
                    </Col>
                   
                    <Col md={6}>
                        <FormGroup>
                            <Input bsSize="md" type="email" name="email" placeholder="Email" valid={!isUserEmailEmpty && shouldShowError ? true : false} invalid={isUserEmailEmpty && shouldShowError ? true : false} value={userEmail} onChange={event => userEmailValidation(event.target.value)}  onFocus={()=>setShouldShowError(false)}/>
                        </FormGroup>
                    </Col>

                </Row>
                
                <Row className="refer_box">
                <Label className="title_label" for="userInfo">Referral Info</Label>
                <Col md={6}>
                        <FormGroup>
                            <Input bsSize="md" type="text" name="referral_name" placeholder="Referral Name" valid={!isReferralNameEmpty && shouldShowError ? true : false} invalid={isReferralNameEmpty && shouldShowError ? true : false} value={referralName} onChange={event => referralNameValidation(event.target.value)} onFocus={()=>setShouldShowError(false)}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Input bsSize="md" type="text" name="referral_number" placeholder="Referral Phone (Optional)" />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Input bsSize="md" type="email" name="referral_email" placeholder="Referral Email" valid={!isReferralEmailEmpty && shouldShowError ? true : false} invalid={isReferralEmailEmpty && shouldShowError ? true : false} value={referralEmail} onChange={event => referralEmailValidation(event.target.value)} onFocus={()=>setShouldShowError(false)}/>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Input type="select" name="selectSkill" id="exampleSelectMulti" onChange={event => onChangeSkill(event.target.value)}>
                                <option value="iOS">iOS</option>
                                <option value="Android">Android</option>
                                <option value="Java">Java</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={12}>

                        <FormGroup>
                            <input ref = {inputRef} type="file" name="user_resume"  onChange={()=>handleAttachment()}  accept=".doc,.docx,.pdf,.pages"/>
                        </FormGroup>
                    </Col>
                    <Col md={8}>
                        <Progress value={progress} color={progress < 100 ? "warning" : "success"}/>
                    </Col>
                    <Col md={4}>
                        <div>
                            <h6> <strong> {progress == 0 ? '' : (progress == 100 ? "Uploaded" : "Uploading...")}</strong> </h6></div>
                    </Col>
                </Row>

                <button onClick={handleSubmit} disabled={!(progress==0 || progress==100)}  >Submit</button>
            </Form>
        </Container>
    </section>
)
}

export default ReferralForm 
